@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400);
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.base-container {
  overflow-y: hidden;
}

.home {
  position: absolute;
}

.aboutme { 
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #333D79FF;
  height: 100vh;
  width: 20%;
  color: white;
  padding-top: 1.5rem; 
  padding-bottom: 1.5rem;
  z-index: 1010;
}

.inner-link {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  margin-top: 1rem;
  /* font-family : 'Raleway', sans-serif; */
  letter-spacing: 1px;
}

.inner-link:hover {
  color: white;
  text-decoration: none;

}

.aboutme img { 
  margin: 0rem 0 .5rem 0;
  height: 10rem;
  width: auto;
  border-radius: 50%;
}

.wrapper {
  padding: 1rem 0 1rem 0;
}

.emphasis {
  font-weight: 600;
  font-size: 1.3rem;
  text-align: left;
}

.small-text {
  position: absolute;
  bottom: 50px;
  font-size: .8rem;
  color: rgb(185, 185, 185);
}

.small-text-2 {
  position: absolute;
  bottom: 0;
  font-size: .3rem;
  color: rgb(185, 185, 185);
  text-align: center;
}

.small-text-2 .link {
  text-decoration: underline;
}

.link {
  text-decoration: none;
  color: inherit;
  margin-top: 1rem;
}

.link:hover {
  text-decoration: none;
  color: grey;
  transition: ease-in-out, .14s;
}

/* ============= Button ============= */
button {
  margin: 10px 0;
  background-color: white;
  padding: 8px 33px;
  border: 2px solid #333D79FF; 
  cursor: pointer;
  color: #333D79FF;
  font-weight: 600;
}


/* ============= TEXTS ============= */
.title {
  font-weight: 700; 
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 2.5rem;
}

.title-1 {
  /* margin: 2rem 0 2rem 0; */
  font-size: 2.2rem; 
  font-weight: 700;
  line-height: 2.8rem;
  margin-bottom: 2rem;

}

.title-2 {
  margin: 1rem 0 1rem 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.title-3 {
  margin: 0rem 0 .1rem 0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* ============= CARDS ============= */
.card-container {
  display: flex; 
  justify-content: space-between; 
  flex-wrap: wrap;
  margin: 1rem 0 1rem 0;
}

.card-container .card {
  display: flex;
  flex: 0 1 calc(50% - 1em);
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem;
  margin: 1.7em 0 1.7em 0;
  border: 0;
  box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  text-align: center;
}

.card-title {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.card-title.icon {
  margin-top: 4rem;
}


.card-descr {
  text-align: center;
}

/* ============= ICONS ============= */
.icon-background {
  position: absolute;
  top: -20px;
  width: 100px; 
  height: 100px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.icon-background.blue {
  background-color: #6998a3;
}

.icon-background.yellow {
  background-color: #d3c9c4;
}

.icon-background.pink {
  background-color: #f3bac3;
}

.icon-background.lightblue {
  background-color: #a4e6f4;
}

.icon-size {
  width: 100px;
  height: 100px;
  padding: 1.5em;
}

.icon-size-sm {
  font-size: 14px;
}

/* ============= HTML TAGS ============= */

ul, li {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
}

/* ============= Animations ============= */
.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}

.underline:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.underline:hover:before {
  visibility: visible;
  width: 100%;
}

.underline-highlight {
  background-image: linear-gradient(120deg, #b3f1ff 0%, #ffd7dd 100%);
  background-repeat: no-repeat;
  background-size: 0 1.3em;
  background-position: 0 0%;
  /* transition: background-size 1s ease-in; */
  -webkit-animation: underline-highlight 1s ease-in-out both;
          animation: underline-highlight 1s ease-in-out both;
          
}

@-webkit-keyframes underline-highlight {
  0% {
    background-size: (0%);
    background-size: (0%);
  }
  100% {
    background-size: 100% 1.3em;
  }
}
@keyframes underline-highlight {
  0% {
    background-size: (0%);
  }
  100% {
    background-size: 100% 1.3em;
  }
}

.slide-right {
	-webkit-animation: slide-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.slide-left {
	-webkit-animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.990) .5s both;
	        animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.990) .5s both;
}

 @-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(2000px);
            transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0x);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(2000px);
            transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

/* ============= Scroll Snap ============= */
/* https://css-tricks.com/practical-css-scroll-snapping/ */
.section-container {
  display: flex;
  flex-flow: column nowrap;
  scroll-snap-type: mandatory;
  /* scroll-snap-points-y: repeat(100%); */
  overscroll-behavior-y: none;
  scroll-snap-type: y mandatory;
  overflow-y: visible;
  /* size of the container */
  height: 90vh;
  scroll-snap-align: start; 
}

/* make scrollbar transparent */
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}

/* for scroll snapping  */
.section {
  scroll-snap-align: start;
  margin-top: 10vh;
  margin-bottom: 20vh;
  /* scroll-snap-stop: always;  */
  height: 90%;
  min-height: -webkit-fill-available;
  /* flex: none; */
}

.first-sec {
  /* margin-bottom: calc(90vh - 25vh); */
  /* padding-left: 5vw;
  padding-right: 5vw; */
  padding-top: 17vh;
  padding-bottom: 0vh;
  margin:0vh 0vh 6vh 5vw;
  overflow: scroll;
  scroll-snap-align: inherit; 
}

.first-sec p {
  width: 500px;
}
